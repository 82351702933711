<template>
    <NdxPageFrame>
        <template #default="slotProps">
            <ProductDetailComponent :parent-width="slotProps.parentWidth"/>
        </template>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import ProductDetailComponent from "../../components/products/detail/ProductDetail";
    import { mapState } from "vuex";

    export default {
        name: "OrderItemDetail",
        components: {NdxPageFrame, ProductDetailComponent},
        computed: {
            ...mapState({
                dataComplete: state => state.productDetail.dataComplete
            })
        },
        created() {
            let orderItemId = null;
            let parentProductId = null;
            if (this.$route.params && 'oid' in this.$route.params) {
                orderItemId = +this.$route.params.oid;
            }
            if (this.$route.params && 'parentProductId' in this.$route.params) {
                parentProductId = +this.$route.params.parentProductId;
            }
            if (orderItemId === null) {
                throw new Error('orderItemId is missing');
            }

            this.$store.dispatch('productDetail/setUpdateModus', true);
            this.$store.dispatch('productDetail/setOrderItemId', orderItemId);

            this.$store.dispatch('basket/findBasketItem', {
                orderItemId: orderItemId,
                parentProductId: parentProductId
            }).then((item) =>
                this.$store.dispatch('productDetail/find', {
                    productId: item.productId
                }).then(() => {
                    this.$store.dispatch('productDetail/setQuantity', {
                        quantity: item.quantity
                    });
                    this.$store.dispatch('productDetail/updateSelectedBudget', item.selectedBudget);
                    for (const feature of item.productFeatures) {
                        this.$store.dispatch('productDetail/updateProductFeature', {
                            feature,
                            value: feature.value
                        });
                    }

                    this.$store.dispatch('productDetail/setQuantityChangeable', item.isQuantityChangeable);
                    this.$store.dispatch('productDetail/setOrderItemQuantityStrategy', item.quantityStrategy);
                    this.$store.dispatch('productDetail/setDataComplete', true);
                })
            );
        },
        beforeUnmount() {
            this.$store.dispatch('productDetail/setUpdateModus', false);
            this.$store.dispatch('productDetail/setOrderItemId', null);
            this.$store.dispatch('productDetail/setOrderItemQuantityStrategy', null);
            this.$store.dispatch('productDetail/setDataComplete', false);
        }
    };
</script>
