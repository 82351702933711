import { createRouter, createWebHashHistory } from 'vue-router';
import {isRef, unref} from "vue";

import Login from "../pages/login/Login";
import PasswordRecover from "../pages/passwordRecover/PasswordRecover";
import PasswordChange from "../pages/passwordChange/PasswordChange";
import QueryTotp from '../pages/2FA/QueryTotp.vue';
import SetupTotp from '../pages/2FA/SetupTotp.vue';
import OptIn from "../pages/optIn/OptIn";
import ClientSelect from "../pages/clientSelect/ClientSelect";
import AppMain from "../wireframes/NdxMain";
import Home from "../pages/home/Home";
import Budgets from "../pages/budgets/Budgets";
import Favorites from "../pages/favorites/Favorites";
import Assets from "../pages/assets/Assets";
import AssetEntryDetail from "../pages/assets/AssetEntryDetail";
import Products from "../pages/products/Products";
import ProductDetail from "../pages/products/ProductDetail";
import Custom from "../pages/custom/Custom";
import Watchlist from "../pages/watchlist/Watchlist";
import WorkflowsInstances from "../pages/workflows/WorkflowsInstances.vue";
import WorkflowsTasks from "../pages/workflows/WorkflowsTasks.vue";
import WorkflowsTaskDetail from "../pages/workflows/WorkflowsTaskDetail.vue";
import WorkflowsInstanceDetail from "../pages/workflows/WorkflowsInstanceDetail.vue";
import WorkflowsInstanceCompletedDetail from "../pages/workflows/WorkflowsInstanceCompletedDetail.vue";
import Basket from "../pages/basket/Basket";
import OrderItemDetail from "../pages/basket/OrderItemDetail";
import BundleItemDetail from "../pages/basket/BundleItemDetail";
import Checkout from "../pages/checkout/Checkout";
import Orders from "../pages/orders/Orders";
import OrderDetails from "../pages/orders/OrderDetails";
import OrderSuccess from "../pages/orderSuccess/OrderSuccess";
import MyAccount from "../pages/myAccount/MyAccount";
import Marketingplanner from "../pages/marketingplanner/Marketingplanner";
import News from "../pages/news/News";
import NewsDetail from "../pages/news/NewsDetail";
import DataCheck from "../pages/dataCheck/DataCheck";
import SearchResults from "../pages/search/SearchResults";
import Documents from "../pages/documents/Documents";
import PageNotFound from "../pages/pageNotFound/PageNotFound";

import {constants} from "@ndx/utilities/twoFactor";

const deepLinkRouteKey = 'deeplink';

const routeDefinition = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        fallback: true
    }, {
        path: '/passwordRecover',
        name: 'PasswordRecover',
        component: PasswordRecover
    }, {
        path: '/passwordChange',
        name: 'PasswordChange',
        component: PasswordChange,
        meta: {requiresAuth: true}
    }, {
        path: '/clientSelect',
        name: 'ClientSelect',
        component: ClientSelect,
        meta: {requiresAuth: true}
    }, {
        path: '/dataCheck',
        name: 'DataCheck',
        component: DataCheck,
        meta: {requiresAuth: true}
    }, {
        path: '/postLogin',
        name: 'PostLogin',
        meta: {requiresAuth: true}
    }, {
        path: '/2fa',
        name: 'QueryTopt',
        component: QueryTotp
    }, {
        path: '/2faSetup',
        name: 'Setup2fa',
        component: SetupTotp
    }, {
        path: '/:pathMatch(.*)*',
        component: AppMain,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/:id/:pathMatch(.*)*',
                component: Home,
                name: 'ShopHomepage'
            }, {
                path: '/:pathMatch(.*)*',
                component: Home,
                name: 'ShopHomepage1'
            }, {
                path: '/:id/optIn/:pathMatch(.*)*',
                name: 'OptIn',
                component: OptIn
            },
            {
                path: '/my-account/:pathMatch(.*)*',
                component: MyAccount,
                name: 'MyAccount',
                children: [
                    {
                        path: '/my-account/user/:pathMatch(.*)*',
                        component: MyAccount,
                        name: 'MyAccountUser'
                    },
                    {
                        path: '/my-account/client/:pathMatch(.*)*',
                        component: MyAccount,
                        name: 'MyAccountClient'
                    },
                    {
                        path: '/my-account/addresses/:pathMatch(.*)*',
                        component: MyAccount,
                        name: 'MyAccountAddresses'
                    },
                    {
                        path: '/my-account/usersettings/:pathMatch(.*)*',
                        component: MyAccount,
                        name: 'MyAccountUserSettings'
                    },
                    {
                        path: '/my-account/substitutes/:pathMatch(.*)*',
                        component: MyAccount,
                        name: 'MyAccountSubstitutes'
                    },
                    {
                        path: '/my-account/absence/:pathMatch(.*)*',
                        component: MyAccount,
                        name: 'MyAccountAbsence'
                    }
                ]
            },
            {
                path: '/marketingplanner/:pathMatch(.*)*',
                component: Marketingplanner,
                name: 'Marketingplanner'
            },
            {
                path: '/:id/documents/:pathMatch(.*)*',
                component: Documents,
                name: 'Documents'
            },
            {
                path: '/news/:pathMatch(.*)*',
                component: News,
                name: 'News'
            },
            {
                path: '/news/:newsId/:pathMatch(.*)*',
                component: NewsDetail,
                name: 'NewsDetail'
            },
            {
                path: '/:id/budgets/:pathMatch(.*)*',
                component: Budgets,
                name: 'Budgets',
                children: [
                    {
                        path: '/:id/budgets/categories/:pathMatch(.*)*',
                        component: Budgets,
                        name: 'BudgetCategories'
                    },
                    {
                        path: '/:id/budgets/products/:budgetId?/:pathMatch(.*)*',
                        component: Budgets,
                        name: 'BudgetProducts'
                    }
                ]
            },
            {
                path: '/:id/assets/:aid?/:pathMatch(.*)*',
                component: Assets,
                name: 'Assets',
                children: [
                    {
                        path: '/:id/assets/:categoryId/:aid?/:pathMatch(.*)*',
                        component: Assets,
                        name: 'AssetsInCategory'
                    }
                ]
            },
            {
                path: '/asset/:aid/:pathMatch(.*)*',
                component: AssetEntryDetail,
                name: 'Asset'
            },
            {
                path: '/:id/favorites/:pathMatch(.*)*',
                component: Favorites,
                name: 'Favorites'
            },
            {
                path: '/:id/watchlist/:pathMatch(.*)*',
                component: Watchlist,
                name: 'Watchlist'
            },
            {
                path: '/:id/workflows/tasks/:pathMatch(.*)*',
                component: WorkflowsTasks,
                name: 'WorkflowsTasks'
            },
            {
                path: '/:id/workflows/tasks/:taskType/:pathMatch(.*)*',
                component: WorkflowsTasks,
                name: 'WorkflowsTasksTypeFiltered'
            },
            {
                path: '/:id/workflows/instances/:pathMatch(.*)*',
                component: WorkflowsInstances,
                name: 'WorkflowsInstances'
            },
            {
                path: '/:id/workflows/instances/:taskType/:pathMatch(.*)*',
                component: WorkflowsInstances,
                name: 'WorkflowsInstancesTypeFiltered'
            },
            {
                path: '/workflows/task/:taskId/:pathMatch(.*)*',
                component: WorkflowsTaskDetail,
                name: 'WorkflowsTaskDetail'
            },
            {
                path: '/workflows/instance/:instanceId/:pathMatch(.*)*',
                component: WorkflowsInstanceDetail,
                name: 'WorkflowsInstanceDetail'
            },
            {
                path: '/workflows/instanceSnapshot/:snapshotId/:pathMatch(.*)*',
                component: WorkflowsInstanceCompletedDetail,
                name: 'WorkflowsInstanceCompletedDetail'
            },
            {
                path: '/:id/orders/:pathMatch(.*)*',
                component: Orders,
                name: 'Orders'
            },
            {
                path: '/:id/order/:orderId/:pathMatch(.*)*',
                component: OrderDetails,
                name: 'OrderDetails'
            },
            {
                path: '/:id/orderSuccess/:orderId/:pathMatch(.*)*',
                component: OrderSuccess,
                name: 'OrderSuccess'
            },
            {
                path: '/:id/products/:pid?/:pathMatch(.*)*',
                component: Products,
                name: 'Products',
                children: [
                    /*
                    {
                        path: '/:id/products/:categoryId/pid/:pid/:pathMatch(.*)*',
                        component: ProductDetail,
                        name: 'Product'
                    },*/
                    {
                        path: '/:id/products/:categoryId/:pid?/:pathMatch(.*)*',
                        component: Products,
                        name: 'ProductsInCategory'
                    }
                ]
            },
            {
                path: '/product/:pid/:pathMatch(.*)*',
                component: ProductDetail,
                name: 'Product'
            },
            {
                path: '/:id/custom/:pathMatch(.*)*',
                component: Custom,
                name: 'Custom'
            },
            {
                path: '/:id/basket/:pathMatch(.*)*',
                component: Basket,
                name: 'Basket'
            },
            {
                path: '/orderItemDetail/:oid/:parentProductId/:pathMatch(.*)*',
                component: OrderItemDetail,
                name: 'OrderItemDetail'
            },
            {
                path: '/bundleItemDetail/:bid/:pathMatch(.*)*',
                component: BundleItemDetail,
                name: 'BundleItemDetail'
            },
            {
                path: '/:id/checkout/:pathMatch(.*)*',
                component: Checkout,
                name: 'Checkout'
            },
            {
                path: '/search/:pathMatch(.*)*',
                name: 'SearchResults',
                component: SearchResults
            },
            {
                path: '/:notFound/:pathMatch(.*)*',
                component: PageNotFound,
                name: 'NotFound'
            }
        ]
    }
];

let appStore;
let appRouter;

const router = (store) => {
    appStore = store;
    appRouter = createRouter({
        history: createWebHashHistory(),
        routes: routeDefinition
    });

    appRouter.beforeEach(async (to) => {
        let checkResult;
        if (to.name === 'PostLogin') {
            await appStore.dispatch('session/refreshState')
                .catch(() => {
                })
                .finally(async () => {
                    if (appStore.getters['user/hasClientSwitch']) {
                        checkResult = {name: 'ClientSelect'};
                    } else {
                        checkResult = getRouteAfterLogin();
                    }
                });
        } else {
            if (!appStore.getters['session/isInitialized']) {
                await appStore.dispatch('session/refreshState')
                    .catch(() => {
                    })
                    .finally(async () => {
                        checkResult = await checkLoggedIn(to);
                    });
            } else {
                checkResult = await checkLoggedIn(to);
            }
        }

        return checkResult;
    });
    appRouter.afterEach(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });

    return appRouter;
};

function checkLoggedIn (to) {
    return new Promise (resolve => {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (!appStore.getters['session/isLoggedIn']) {
                saveDeepLink(to);
                // url needs logged in user but could not find one
                resolve({
                    name: 'Login'
                });
                return;
            } else if (to.name !== 'PasswordChange' && appStore.getters['session/isPasswordChangeRequired']) {
                // user is logged in but has to change password so no other url than password change allowed
                resolve({
                    name: 'PasswordChange'
                });
                return;
            } else if (to.name !== 'PasswordChange' && to.name !== 'OptIn' && // allowed pages if gtc page is mandatory
                appStore.getters['session/showOptinPage']
            ) {
                // user is logged in but has to change password so no other url than password change allowed
                resolve(appStore.getters['shop/getRouteByType']('gtc'));
                return;
            } else if (to.name === 'PasswordRecover') {
                // user wants a new password
                resolve(true);
                return;
            }
        }

        resolve(true);
    });
}

window.addEventListener('forceLogin', () => {
    if (appRouter.currentRoute.name !== 'Login') {
        saveDeepLink(appRouter.currentRoute);
        appRouter.push({ name: 'Login' });
    }
});

window.addEventListener('twoFactor', (evt) => {
    switch (evt.detail.mode) {
        case constants.totpRequired: appRouter.push({ name: 'QueryTopt' });break;
        case constants.setupRequired : appRouter.push({ name: 'Setup2fa' });break;
        default: throw new Error(`Unknown two-factor event mode ${evt.detail.mode}`);
    }
});

/**
 * Returns the route after login
 *
 * @returns {Object}
 */
export const getRouteAfterLogin = function () {
    let route = null;
    const redirects = appStore.getters['session/getRedirects'];
    const getRouteByType = appStore.getters['shop/getRouteByType'];

    if (redirects && 'login' in redirects) {
        if (redirects.login === 'dataCheck') {
            route = {name: 'DataCheck'};
        } else {
            const deepLinkRoute = window.localStorage.getItem(deepLinkRouteKey);
            const _route = getRouteByType(redirects.login);

            if (deepLinkRoute) {
                window.localStorage.removeItem(deepLinkRouteKey);
                route = JSON.parse(deepLinkRoute);
            } else if (_route !== null) {
                route = _route;
            }
        }
    }
    if (!route) {
        const _route = getRouteByType('shopHomepage');
        if (_route !== null) {
            route = _route;
        }
    }
    if (!route) {
        route = {path: '/'};
    }

    return route;
};

/**
 * Returns the route after dataCheck
 *
 * @returns {Object}
 */
export const getRouteAfterDataCheck = function () {
    let route = null;
    const redirects = appStore.getters['session/getRedirects'];
    const getRouteByType = appStore.getters['shop/getRouteByType'];

    if (redirects && 'dataCheck' in redirects) {
        const deepLinkRoute = window.localStorage.getItem(deepLinkRouteKey);
        const _route = getRouteByType(redirects.dataCheck);
        if (deepLinkRoute) {
            window.localStorage.removeItem(deepLinkRouteKey);
            route = JSON.parse(deepLinkRoute);
        } else if (_route !== null) {
            route = _route;
        }
    }
    if (!route) {
        const _route = getRouteByType('shopHomepage');
        if (_route !== null) {
            route = _route;
        }
    }
    if (!route) {
        route = {path: '/'};
    }

    return route;
};

function saveDeepLink(route) {
    const isBlacklistedRouteName = (route) => {
        const listOfNonDeepLinkRoutes = [
            'Login',
            'PostLogin',
            'OptIn',
            'PasswordRecover',
            'PasswordChange',
            'ClientSelect',
            'DataCheck',
            'NotFound',
            'QueryTopt',
            'Setup2fa',
        ];

        return listOfNonDeepLinkRoutes.includes(route.name);
    };

    const isPageNotFoundPath = (route) => {
        return route.path.includes('pageNotFound');
    };

    const tmp = isRef(route) ? unref(route) : route;

    if (!isBlacklistedRouteName(tmp) && !isPageNotFoundPath(tmp)) {
        // we have to build our own route obj, 'cause the original one could not be stringified
        const routeObj = {
            fullPath: tmp.fullPath,
            hash: tmp.hash,
            href: tmp.href,
            meta: tmp.meta,
            name: tmp.name,
            params: tmp.params,
            path: tmp.path,
            query: tmp.query
        };

        try {
            window.localStorage.setItem(
                deepLinkRouteKey,
                JSON.stringify(routeObj)
            );
        } catch (e) {
            console.error(e);
        }
    }
}

export default router;
