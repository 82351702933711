import lodashMerge from "lodash/merge";
import basketApi from '../../api/basket';
import { basketGetters } from './base/basket';
import lodashClone from "lodash/cloneDeep";

const EMPTY_BASKET = {
    bundleTrees: [],
    groups: []
};

//state
const state = {
    basket: lodashClone(EMPTY_BASKET),
    basketItemCount: 0,
    watchlist: [],
    watchlistItemCount: 0,
    checkoutData: null,
    printView: false,
    contentBlocks: {},
    multiAddressAddresses: {},
    quickCheckoutData: null,
    orderingFailure: null,

    showFlyIn: false,
    newlyAdded: null,
    showBasketSummary: true
};

// getters
const getters = lodashMerge({}, basketGetters, {
    isMultiAddressDelivery(state) {
        if (state.checkoutData) {
            return state.checkoutData.isMultiAddress;
        }
        if (state.basket?.summary) {
            return state.basket.summary.isMultiAddress;
        }
        return false;
    },
    getMultiAddressForItem(state) {
        return (orderItemId) => {
            if (state.checkoutData && orderItemId in state.checkoutData.orderItems) {
                return state.checkoutData.orderItems[orderItemId];
            }
            return null;
        };
    },
    getDefaultDeliveryAddress(state) {
        return state.checkoutData?.deliveryAddress;
    },
    getAddress(state) {
        return (addressId) => {
            for (const scId in state.multiAddressAddresses) {
                for (const idx in state.multiAddressAddresses[scId].addresses) {
                    if (+state.multiAddressAddresses[scId].addresses[idx].id === +addressId) {
                        return state.multiAddressAddresses[scId].addresses[idx];
                    }
                }
            }
        };
    },
    isUseableDeliveryAddress() {
        return (address) => (address.company || address.firstname || address.lastname) && address.countryId;
    },
    getOrderItem(state) {
        return (orderItemId) => {
            let orderItem = null;
            if (Array.isArray(state.basket?.groups)) {
                state.basket.groups.forEach(group => {
                    group.items.forEach(item => {
                        if (item.orderItemId == orderItemId) {
                            orderItem = item;
                        }
                    });
                });
            }

            return orderItem;
        };
    },
    itemBudgetExceededd(state, getters) {
        return (orderItemId) => {
            const orderItem = getters.getOrderItem(orderItemId);
            if (!orderItem?.budget) {
                return false;
            }

            let costs = 0.0;
            getters.getOrderItems.forEach(item => {
                if (item.budget?.id === orderItem.budget.id) {
                    costs += getters.budgetRelevantCosts(item);
                }
            });

            return costs > parseFloat(orderItem.budget.remainingValue);
        };
    }
});

// actions
const actions = {
    showBasketSummary({ commit}, data) {
        commit('SET_SHOW_BASKET_SUMMARY', data);
    },
    getAllData({dispatch}) {
        return new Promise((resolve, reject) => {
            basketApi.getAllData()
                .then((result) => {
                    dispatch('setAllData', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setAllData({commit, dispatch}, data) {
        if ('basketGroups' in data) {
            this.dispatch('checkout/setBasketGroups', data.basketGroups, {root:true});
        }
        if('basket' in data) {
            commit('SET_BASKET', data.basket);

            if ('basketItemCount' in data.basket) {
                commit(
                    'SET_BASKETITEM_COUNT',
                    data.basket.basketItemCount
                );
            }
            if ('watchItemCount' in data.basket) {
                commit(
                    'SET_WATCHLISTITEM_COUNT',
                    data.basket.watchItemCount
                );
            }
        }
        if ('checkoutData' in data) {
            dispatch('checkout/setHasCustomCheckoutStep', data.checkoutData.hasCustomCheckoutStep, {root:true});
            commit('SET_CHECKOUT_DATA', data.checkoutData);
        }
        if ('watchlist' in data) {
            commit('SET_WATCHLIST', data.watchlist);
        }
    },
    addToBasket(ctx, data) {
        return new Promise((resolve, reject) => {
            basketApi.addToBasket(data.productId, data.quantity, data.productFeatureValues, data.campaignRunId,
                data.getFullBasket || false, data.desiredBudgetId || null)
                .then((result) => {
                    const basketData = 'groups' in result ?
                        result :
                        {
                            groups: [{items: result.basketItems}],
                            basketItemCount: result.basketItemCount,
                            watchItemCount: result.watchItemCount
                        };

                    this.dispatch(
                        'basket/setAllData', {
                            basket: basketData,
                            watchlist: result.watchItems
                        }
                    );
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateBasketItem(ctx, data) {
        return new Promise((resolve, reject) => {
            basketApi.updateBasketItem(
                data.orderItemId,
                data.quantity,
                data.productFeatureValues,
                data.desiredBudgetId
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getBasket({dispatch}) {
        return new Promise((resolve, reject) => {
            basketApi.getBasket()
                .then((basket) => {
                    dispatch('setAllData', {
                        basket: basket,
                        watchlist: basket.watchItems
                    });
                    resolve(basket);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getCheckoutData({dispatch}) {
        return new Promise((resolve, reject) => {
            basketApi.getCheckoutData()
                .then((result) => {
                    dispatch('setAllData', {checkoutData: result});
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    resetQuickCheckoutData({commit}) {
        commit('SET_QUICKCHECKOUT_DATA', null);
    },
    getQuickCheckoutData({commit}) {
        return new Promise((resolve, reject) => {
            basketApi.getQuickCheckoutData()
                .then((result) => {
                    commit('SET_QUICKCHECKOUT_DATA', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setAddress({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.setAddress(data.addressType, data.addressId)
                .then((result) => {
                    dispatch('setAllData', result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    order({commit}) {
        return new Promise((resolve, reject) => {
            basketApi.order()
                .then((result) => {
                    commit('SET_BASKET', lodashClone(EMPTY_BASKET));
                    commit('SET_BASKETITEM_COUNT', 0);
                    resolve(result);
                })
                .catch((error) => {
                    commit('SET_ORDERING_FAILURE', error);
                    reject(error);
                });
        });
    },
    resetOrderingFailure({commit}) {
        commit('SET_ORDERING_FAILURE', null);
    },
    updateQuantity({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.updateQuantity(data.basketItemId, data.quantity)
                .then(async (newBasketData) => {
                    await dispatch('setAllData', {basket: newBasketData});
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateBudgetUsed({dispatch}, data) {
        const checkRemainingValue = 'checkRemainingValue' in data ? data.checkRemainingValue : true;

        return new Promise((resolve, reject) => {
            basketApi.updateBudgetUsed(data.basketItemId, data.budgetId, checkRemainingValue)
                .then((result) => {
                    dispatch('setAllData', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateBudgetUsedBulk({dispatch}, data) {
        const checkRemainingValue = 'checkRemainingValue' in data ? data.checkRemainingValue : true;

        return new Promise((resolve, reject) => {
            basketApi.updateBudgetUsedBulk(data.basketItemIdList, data.budgetId, checkRemainingValue)
                .then((result) => {
                    dispatch('setAllData', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeFromBasket({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.removeFromBasket(data.basketItemId, data.listType)
                .then((newBasketData) => {
                    dispatch('setAllData', {basket: newBasketData});
                    this.dispatch('workflowTask/getOpenTaskCountForUser');
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    moveBundleToBasket(ctx, data) {
        return new Promise((resolve, reject) => {
            basketApi.moveBundleToBasket(data.bundleId)
                .then((newBasketData) => {
                    resolve(newBasketData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    moveBundleToWatchlist({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.moveBundleToWatchlist(data.bundleId, !!data.skipHydration)
                .then((newBasketData) => {
                    if (data.skipHydration !== true) {
                        dispatch('setAllData', {basket: newBasketData});
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeBundle({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.removeBundle(data.bundleId, data.listType, !!data.skipHydration)
                .then((infos) => {
                    if (data.skipHydration !== true) {
                        dispatch('setAllData', {basket: infos});
                    }
                    this.dispatch('workflowTask/getOpenTaskCountForUser');
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getPrintContentBlocks() {
        return new Promise((resolve, reject) => {
            basketApi.getPrintContentBlocks()
                .then((blocks) => {
                    resolve(blocks);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    findBasketItem(ctx, data) {
        return new Promise((resolve, reject) => {
            basketApi.findBasketItem(data.orderItemId, data.parentProductId)
                .then((basketItem) => {
                    resolve(basketItem);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateDeliveryOptionOrderItem({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.updateDeliveryOptionOrderItem(
                data.orderItemId, data.optionId
            ).then((result) => {
                dispatch('setAllData', result);
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    },
    updateDeliveryOptionAddressItem({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.updateDeliveryOptionAddressItem(
                data.orderItemAddressId, data.optionId
            ).then((result) => {
                dispatch('setAllData', result);
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    },
    updateAddressComments({dispatch}, data) {
        return new Promise((resolve, reject) => {
            basketApi.updateAddressComments(data)
                .then((checkoutData) => {
                    dispatch('setAllData', {checkoutData: checkoutData});
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveMultiAddress({dispatch}, multiAddressInfo) {
        return new Promise((resolve, reject) => {
            basketApi.saveMultiAddress(multiAddressInfo)
                .then((result) => {
                    dispatch('setAllData', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deactivateMultiAddress({dispatch}) {
        return new Promise((resolve, reject) => {
            basketApi.deactivateMultiAddress()
                .then((result) => {
                    dispatch('setAllData', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getMultiAddressDeliveryAddresses({commit}) {
        return new Promise((resolve, reject) => {
            basketApi.getMultiAddressDeliveryAddresses()
                .then((result) => {
                    commit('SET_MULTIADDRESS_ADRESSES', result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getWatchlist({commit, dispatch}, {filterCriteria, offset, limit}) {
        return new Promise((resolve, reject) => {
            basketApi.getWatchlist(filterCriteria, offset, limit)
                .then((result) => {
                    dispatch('setAllData', {watchlist: result.list});
                    commit(
                        'SET_BASKETITEM_COUNT',
                        result.basketItemCount
                    );
                    commit(
                        'SET_WATCHLISTITEM_COUNT',
                        result.watchItemCount
                    );
                    resolve({
                        list: {orderItems: result.list, bundleTrees: result.bundleTrees},
                        count: result.watchItemCount
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    setBasketItemCount({commit}, data) {
        commit('SET_BASKETITEM_COUNT', data);
    },
    setWatchlistItemCount({commit}, data) {
        commit('SET_WATCHLISTITEM_COUNT', data);
    },
    showPrintView({commit}, data) {
        commit('SET_CONTENT_BLOCKS', data.contentBlocks);
        commit('SET_PRINTVIEW', data.printView);
    },
    removeApproval(ctx, campaignRunId) {
        return new Promise((resolve, reject) => {
            basketApi.removeApproval(campaignRunId)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    showBasketFlyIn({commit}, data) {
        commit('SET_SHOW_FLY_IN', true);
        commit('SET_NEWLY_ADDED', data);
    },
    hideBasketFlyIn({commit}) {
        commit('SET_SHOW_FLY_IN', false);
        commit('SET_NEWLY_ADDED', null);
    },
    resetNewlyAdded({commit}) {
        commit('SET_NEWLY_ADDED', null);
    },
    findBundledOrderItems(ctx, {bundleId}) {
        return new Promise((resolve, reject) => {
            basketApi.findBundledOrderItems(bundleId)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.basket = lodashClone(EMPTY_BASKET);
        state.watchlist = [];
        state.checkoutData = null;
        state.multiAddressAddresses = {};
        state.printView = false;
        state.contentBlocks = {};
        state.basketItemCount = 0;
        state.watchlistItemCount = 0;
        state.quickCheckoutData = null;
        state.orderingFailure = null;
    },
    SET_SHOW_BASKET_SUMMARY(state, data) {
        state.showBasketSummary = data;
    },
    SET_BASKET(state, basket) {
        state.basket = basket;
    },
    SET_CHECKOUT_DATA(state, checkoutData) {
        state.checkoutData = checkoutData;
    },
    SET_MULTIADDRESS_ADRESSES(state, addressData) {
        state.multiAddressAddresses = addressData;
    },
    SET_CONTENT_BLOCKS(state, contentBlocks) {
        state.contentBlocks = contentBlocks;
    },
    SET_PRINTVIEW(state, printView) {
        state.printView = printView;
    },
    SET_BASKETITEM_COUNT(state, count) {
        state.basketItemCount = count;
    },
    SET_WATCHLIST(state, list) {
        state.watchlist = list;
    },
    SET_WATCHLISTITEM_COUNT(state, count) {
        state.watchlistItemCount = count;
    },
    SET_QUICKCHECKOUT_DATA(state, data) {
        state.quickCheckoutData = data;
    },
    SET_ORDERING_FAILURE(state, error) {
        state.orderingFailure = error;
    },
    SET_SHOW_FLY_IN(state, show) {
        state.showFlyIn = show;
    },
    SET_NEWLY_ADDED(state, orderItemId) {
        state.newlyAdded = orderItemId;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
